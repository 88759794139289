.promo {
  background-color: var(--color-cover);
}

.promo__container {
  max-width: 1280px;
  margin: 0 auto;
}

.promo__title {
  font-size: 31px;
  letter-spacing: -1.24px;
  line-height: 39px;
  text-align: center;
  font-weight: 400;

  padding: 207px 10px 246px;
  margin: 0;

  background-color: var(--color-cover);
  background-image: url(../../../../images/pic__COLOR_landing-logo.svg);
  background-repeat: no-repeat;
  background-position: center 142px;
}

@media (min-width: 768px) {
  .promo__title {
    padding: 326px 19px 342px;
    letter-spacing: -2px;
    line-height: 58px;
    font-size: 50px;

    background-size: 320px;
    background-position-y: 224px;
  }
}

@media (min-width: 1280px) {
  .promo__title {
    padding: 184px 275px 224px;
    line-height: 58px;
    font-size: 50px;
    background-position-y: 82px;
  }
}