.footer {
  padding: 79px var(--indent-mobile) 20px;
  font-size: 12px;
  text-align: center;
  line-height: 15px;
}

.footer__container {
  max-width: 1280px;
  margin: 0 auto;
}

.footer__description {
  color: var(--color-grey8B);
  padding-bottom: 21px;
  position: relative;
  margin: 0;
}

.footer__description::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--color-grey42);
  bottom: 0;
  left: 0;
}

.footer__list {
  list-style: none;
  width: 100%;
  margin: 30px 0;
  padding: 0;
}

.footer__item:not(:last-of-type) {
  margin-bottom: 12px;
}

.footer__link {
  color: var(--color-white);
  text-decoration: none;
}

.footer__copyright {
  color: var(--color-grey8B);
  margin: 0;
}

@media (min-width: 768px) {
  .footer {
    padding: 79px var(--indent-tablet) 0;
  }

  .footer__description {
    font-size: 13px;
    line-height: 16px;
  }

  .footer__wrapper {
    display: flex;
    padding: 20px 0;

    font-size: 13px;
    line-height: 16px;
    justify-content: space-between;
  }

  .footer__nav {
    order: 1;
  }

  .footer__list {
    display: flex;
    margin: 0;
    gap: 20px;
  }

  .footer__item:not(:last-of-type) {
    margin-bottom: 0;
  }

  .footer__button-exit {
    font-size: 13px;
    line-height: 16px;
  }
}

@media (min-width: 1280px) {
  .footer {
    padding-left: var(--indent-desktop);
    padding-right: var(--indent-desktop);
  }

}