.portfolio {
  padding: 0 14px 50px;
}

.portfolio__container {
  max-width: 1280px;
  margin: 0 auto;
}

.portfolio__title {
  color: var(--color-greyA0);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  margin-bottom: 20px;
}

.portfolio__list {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.portfolio__item {
  position: relative;

  padding: 20px 0;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.72px;
}

.portfolio__item:not(:first-of-type)::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--color-greyE6);
  left: 0;
  top: -1px;
}

.portfolio__link {
  text-decoration: none;
  color: var(--color-white);
  position: relative;
  display: block;
  width: 100%;
}

.portfolio__link::after {
  content: '↗';
  position: absolute;
  right: 0;
  top: 0;
  line-height: 28px;
  min-width: 18px;
}

.portfolio__link-text {
  margin: 0;
}

@media (min-width: 768px) {
  .portfolio {
    padding: 0 50px 70px;
  }

  .portfolio__title {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 30px;
  }

  .portfolio__item {
    font-size: 28px;
    letter-spacing: -1.12px;
    line-height: 50px;
  }

  .portfolio__item::after {
    top: 25px;
  }

  .portfolio__link::after {
    line-height: 60px;
    min-width: 27px;
  }
}

@media (min-width: 1280px) {
  .portfolio {
    padding-left: var(--indent-desktop);
    padding-right: var(--indent-desktop);
    padding-bottom: 105px;
  }

  .portfolio__item {
    font-size: 30px;
  }

  .portfolio__item:not(:first-of-type)::before {
    background-color: var(--color-grey42);
  }
}