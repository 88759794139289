.navigation__list {
  width: 100%;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 3px;
  padding: 25px 0;
  list-style: none;
  margin: 0;
  display: flex;
  gap: 24px;
  justify-content: center;
  background-color: var(--color-grey27);
}

.navigation__link {
  color: var(--color-white);
}

@media (min-width: 768px) {
  .navigation__list {
    padding: 24px 0;
    font-size: 13px;
    gap: 40px;
    line-height: 18px;
  }
}
