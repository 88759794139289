.movies__item {
  background: var(--color-grey2F);
  cursor: pointer;
  position: relative;
  border-radius: 9px;
}

.movies__link {
  text-decoration: none;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
}

.movies__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  order: 1;
  margin: 14px 12px;
}

.movies__title {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin: 0;
  width: calc(100% - 40px);
}

.movies__photo {
  display: block;
  width: 100%;
  aspect-ratio: 1.786;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.movies__duration {
  width: calc(100% - 40px);
  line-height: 13px;
  color: var(--color-grey8B);
  margin: 0;
}

.movies__button {
  width: 32px;
  height: 32px;
  background-color: var(--color-grey42);
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 16px;
  right: 12px;
  z-index: 10;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}

.movies__button_like {
  background-image: var(--icon-dislike);
}

.movies__button_deleet {
  background-image: var(--icon-delete);
  background-size: 14px 14px;
}

.movies__button_like-active {
  background-image: var(--icon-like);
}

@media (min-width: 520px) {
  .movies__link {
    flex-direction: row;
    justify-content: space-between;
  }

  .movies__photo {
    order: 1;
    width: 220px;
    height: 122px;
    padding: 6px;
    border-radius: 12px;
  }

  .movies__button {
    left: 22px;
    bottom: 22px;
  }

  .movies__info {
    width: 100%;
    margin: 22px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 13px;
  }
}

@media (min-width: 768px) {
  .movies__title {
    font-size: 18px;
    line-height: 22px;
  }

  .movies__link:hover {
    outline: none;
    box-shadow: 0 0 5px var(--color-second-two-50);
    border-radius: 9px;
  }

  .movies__button:hover {
    opacity: var(--hover-button);
  }
}