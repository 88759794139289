.main {
  display: flex;
  flex-direction: column;
  flex: auto;
  box-sizing: border-box;
}

.main__container {
  padding: 70px var(--indent-mobile);
}

.main_movies {
  padding: 0 var(--indent-mobile);
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .main__container {
    padding: 90px var(--indent-tablet);
  }

  .main_movies {
    padding: 0 var(--indent-tablet);
  }
}

@media (min-width: 1280px) {
  .main__container {
    padding: 110px var(--indent-desktop)
  }

  .main_movies {
    padding: 0 var(--indent-desktop);
  }
}