@import url(./vendor/normalize.css);
@import url(./vendor/inter.css);
@import url(./vendor/variables.css);

html {
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
}

.page {
  width: 100%;
  min-height: 100dvh;
  box-sizing: border-box;
  background-color: var(--color-main);
  color: var(--color-white);

  display: flex;
  flex-direction: column;
  margin: 0 auto;

  font-family: 'Inter', Arial, sans-serif;
  font-weight: 400;
  font-size: var(--text-mobile);
  line-height: var(--height-mobile);
}

.page:has(.header_active) {
  overflow-y: hidden;
}

.page__wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page__container {
  margin: 0 auto;
}

.button {
  cursor: pointer;
  transition: 0.5s opacity ease-out;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0;
}

.visuali {
  visibility: hidden;
}

@media (min-width: 880px) {

  *:focus-visible,
  button:focus-visible,
  [type="button"]:focus-visible,
  [type="reset"]:focus-visible,
  [type="submit"]:focus-visible {
    outline-color: var(--color-second-two-50);
    outline-style: solid;
    outline-width: 2px;
    outline-offset: 1px;
    border-radius: 5px;
  }

  .main {
    flex: auto;
    display: flex;
    flex-direction: column;
  }
}