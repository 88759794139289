.error {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100dvh;
  text-align: center;
  padding: 329px var(--indent-mobile) 30px;
}

.error__title {
  margin: 0;
  font-size: 80px;
  font-weight: 400;
  line-height: 97px;
}

.error__subtitle {
  font-size: 12px;
  line-height: 15px;
  margin: 10px 0 auto;
}

.error__link {
  font-size: 12px;
  line-height: 15px;
  color: var(--color-second-two);
  text-decoration: none;
}


@media (min-width: 768px) {
  .error {
    padding: 407px var(--indent-tablet) 60px;
  }

  .error__title {
    font-size: 140px;
    line-height: 169px;
  }

  .error__subtitle {
    font-size: 16px;
    line-height: 19px;
    margin: 5px 0 184px;
  }

  .error__link {
    font-size: 14px;
    line-height: 17px;
  }
}

@media (min-width: 1280px) {
  .error {
    padding: 246px var(--indent-desktop) 60px;
  }
}