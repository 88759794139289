.form {
  box-sizing: border-box;
  width: 100%;
}

.form__label {
  display: block;
  position: relative;
}

.form__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.form__item {
  position: relative;
}

.form__button-save {
  cursor: pointer;
  transition: 0.5s opacity ease-out;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0;
  background: var(--color-second-two);
  padding: 8px 10px;
  border-radius: 48px;
  color: var(--color-white);
}

.form__message {
  font-size: 11px;
  line-height: 13px;
  transition: 0.5s;
  min-height: 14px;
  padding: 0;
  text-align: center;
  margin-top: auto;
}

.form__message_error {
  color: var(--color-selection);
}

.form__message_ok {
  color: var(--color-second-one);
}

.form__button-save:disabled {
  cursor: default;
  opacity: 0.3;
}

/* Auth */
.form_type_register,
.form_type_login {
  display: flex;
  flex-direction: column;
  flex: auto;
  height: 100%;
  max-width: 396px;
}

.form__list_type_register,
.form__list_type_login {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-bottom: auto;
}

.form__button-save_type_register,
.form__button-save_type_login {
  width: 100%;
  padding: 15px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 15px;
}

/* Profile */
.form_type_profile {
  display: flex;
  flex-direction: column;
  /* min-height: 441px; */
}

.form__list_type_profile {
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-bottom: auto;
}

.form_active .form__button-save_type_profile {
  width: 100%;
  border-radius: 3px;
  padding: 13px 15px 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

@media (min-width: 768px) {
  .form__button-save:not([disabled]):hover {
    opacity: var(--hover-button);
  }

  /*Auth */
  .form__list_type_login,
  .form__list_type_register {
    margin-bottom: 35px;
  }

  .form_type_register,
  .form_type_login {
    flex: 0;
    min-height: 379px;
  }

  .form__button-save_type_register,
  .form__button-save_type_login {
    font-size: 14px;
    line-height: 17px;
    padding-top: 13px;
    padding-bottom: 15px;
  }

  /* Profile */
  .form__button-save_type_profile {
    font-size: 13px;
    line-height: 16px;
  }

  .form_type_profile {
    min-height: 263px;
  }

  .form__message {
    font-size: var(--text-tablet);
    line-height: vat(--height-tablet);
  }
}

@media (min-width: 1280px) {

  .form__button-save_type_register,
  .form__button-save_type_login {
    font-size: 14px;
    line-height: 17px;
    padding-top: 13px;
    padding-bottom: 15px;
  }

  /* Profile */
  .form_type_profile {
    min-height: 271px;
  }

  .form_active.form_type_profile {
    min-height: 330px;
  }

  .form__message {
    font-size: var(--text-desktop);
    line-height: vat(--height-desktop);
  }
}