.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 396px;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;
  flex: auto;
}

.auth__title {
  width: 100%;
  margin: 0 0 80px;
  font-size: 24px;
  line-height: 29px;
  font-weight: 500;

  text-align: center;
}

.auth__wrapper {
  display: flex;
  padding: 14px 0 30px;
  width: 100%;
  justify-content: center;
  gap: 7px;
}

.auth__text {
  margin: 0;
  color: var(--color-grey8B);
  font-size: 12px;
  line-height: 15px;
}

.auth__link {
  text-decoration: none;
  color: var(--color-second-two);
  font-size: 12px;
}


@media (min-width: 768px) {
  .auth {
    padding: 0;
  }

  .auth__link:hover {
    opacity: var(--hover-link);
  }

  .auth__text {
    font-size: 14px;
    line-height: 17px;
  }

  .auth__link {
    font-size: 14px;
    line-height: 17px;
  }

  .auth__title {
    margin: 0 0 40px;
    font-size: 24px;
    line-height: 29px;
    text-align: start;
  }
  
  .auth__wrapper {
    padding-top: 16px;
    gap: 3px;
  }
}
